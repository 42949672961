import React, { useState, useEffect } from 'react'
import {useNavigate } from 'react-router-dom';
import { getUserDetails, logout } from '../services/UserService';
import { getAllCohortData } from '../services/CohortService';
import { getCustomerDetail } from '../services/CustomerDashService';
import { userManualUrl } from '../utility/constants';

export default function AdminDashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [cohorts, setCohorts] = useState([]);
    const [customer, setCustomer] = useState({
        "id": 1,
        "name": "",
        "desc": "",
        "logo_link": null,
        "subscription_code": "None",
        "subscription_start_date": "2024-05-23T00:00:00.000Z",
        "subscription_end_date": "2024-06-23T00:00:00.000Z",
        "subscription_status": "ACTIVE",
        "max_learners": "0",
        "customer_id": "",
        "num_user": "0",
        "num_courses": "0",
        "courses": [],
        "num_modules": "0"
    });
    const [shakeActive, setShakeActive] = useState(true);

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        } else if(userDetails.user.terms_and_conditions_accepted === false){
            navigate('/termsandconditions');
        } else if(userDetails.user.need_password_change === true){
            navigate('/admin/adminpasswordreset');
        } else{
            setUserDetails(userDetails);
            getAllData(userDetails)
        }
        const timer = setTimeout(() => {
            setShakeActive(false);
        }, 5000);
        return () => clearTimeout(timer);
    },[]);

    const getAllData = async (userDetails) => {
        setLoading(true);
        await getCohorts(userDetails.token);
        await getCustomerDetailData(userDetails.token);
        setLoading(false);
    };

    const getCohorts = async (token) => {
        const cohortsData = await getAllCohortData(token);
        if(cohortsData.error){
            return [];
        }else{
            setCohorts(cohortsData.data);
        }
    };

    const getCustomerDetailData = async (token) => {
        const customerData = await getCustomerDetail(token);
        if(customerData.error === false){
            customerData.data['num_user'] = customerData.data['num_user'] === undefined || customerData.data['num_user'] === null ? 0 : customerData.data['num_user'];
            setCustomer(customerData.data);
        }
    };

    const openManageLearner = async (cohort) => {
        sessionStorage.setItem("cohortLearner", JSON.stringify(cohort));
        navigate('/admin/managelearner');
    };

    const openHelp = async () => {
        window.open(userManualUrl, '_blank');
    }

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
            <div className='dash-container pt-10'>
                <h3 className='text-2xl text-black pacifico-regular text-center mb-3 lg:hidden'>Welcome {userDetails.user.first_name}!</h3>
                <div>
                    <div className='first-course-row flex flex-wrap md:flex-nowrap items-center justify-center gap-x-20 lg:pl-12 pl-6 pr-6 lg:pr-0'>

                        <div className='w-full lg:w-1/2 rounded-3xl shadow-md bg-gradient-to-r from-[#6E3174] to-[#9831A3] lg:p-14 p-8 mb-10 md:mb-0'>
                            <div className='flex'>
                                <div className='w-1/3 pr-4'>
                                    <img className='' src='../images/dash-crown.svg' alt='crown'/>
                                </div>
                                <div className='w-2/3 pl-8'>
                                    <div className='mb-4'>
                                        <p className='text-sm lg:text-4xl text-white inter-700'>{customer.subscription_code} Plan</p>
                                        <p className='text-sm lg:text-3xl text-white inter-300'>{customer.max_learners} User License</p>
                                    </div>
                                    <div>
                                        <p className='text-sm lg:text-xl inter-700 text-[#F9EA98]'>{customer.num_courses} Technologies</p>
                                        <p className='text-sm lg:text-xl inter-700 text-[#F9EA98]'>{customer.num_modules} Modules</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/2 pl-10 lg:pl-0'>
                            <div className='plan-courses'>
                                <div className='plan-dtl flex items-center mb-7'>
                                    <div className='plan-icon w-8 lg:w-11 text-center'>
                                        <img className='w-5 lg:w-10 mx-auto' src='../images/group-share.svg' alt='group share'/>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'><span className='inter-700'>{cohorts.length}</span> Cohorts </p>
                                    </div>
                                </div>
                                <div className='plan-dtl flex items-center mb-7'>
                                    <div className='plan-icon w-8 lg:w-11 text-center'>
                                        <img className='w-5 lg:w-10 mx-auto' src='../images/learner-boy.svg' alt='learner'/>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'><span className='inter-700'>{parseInt(customer.num_user)}</span> Learners Added</p>
                                    </div>
                                </div>
                                <div className='plan-dtl flex items-center mb-0'>
                                    <div className='plan-icon w-8 lg:w-11 text-center'>
                                        <img className='w-5 lg:w-10 mx-auto' src='../images/chair.svg' alt='chair'/>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text-sm lg:text-xl inter-400 text-black lg:ml-4 ml-2'><span className='inter-700'>{parseInt(customer.max_learners) - parseInt(customer.num_user)}</span> Seats Available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dash-univ-wrap mt-12 md:mt-8 lg-mt-12'>
                    <div className='dash-course-heading lg:px-12 px-6 border-b-2 border-[#D0D0D0] pb-2'>
                        <h3 className='text-lg lg:text-2xl inter-700 text-[#366494]'>My Cohorts</h3>
                    </div>
                    <div className='univ-box-wrap flex flex-wrap gap-y-6 md:gap-x-[5%] lg:gap-x-[3.5%] mt-7 lg:px-12 px-6 pb-12'>
                        {cohorts.map((value, index) => (
                            <div key={index} className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                                <a onClick={() => openManageLearner(value)} className='cursor-pointer univ-box-sngl flex flex-col justify-between min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                    <div>
                                        <div className='univ-name'>
                                            <p className='text-base lg:text-base 2xl:text-lg text-white inter-700'>{value.org_name !== undefined && value.org_name !== null ? value.org_name : ""}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='univ-lernar mb-2'>
                                            <p className='text-xl lg:text-2xl 2xl:text-3xl text-white inter-700'>{value.cohort_desc}</p>
                                            <p className='text-lg lg:text-xl 2xl:text-2xl text-white inter-600'>{value.cohort_learners_count === null ? 0 : value.cohort_learners_count} Learners</p>
                                        </div>
                                        <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={shakeActive? 'manual-icon-box bounce2 fixed bottom-8 right-8' : 'manual-icon-box fixed bottom-8 right-8'}>
                    <a onClick={() => openHelp()} className='manual-icon bg-white hover:bg-[#C13F63] rounded-full flex items-center justify-center w-12 h-12 button-shadow transition duration-300 cursor-pointer'>
                        <img className='transition duration-300' src='../images/help-icon.svg' alt='help'/>
                    </a>
                </div>
            </div>
          )}
        </div>
    );
}