import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addContactUs } from '../services/UserService';
import Swal from 'sweetalert2';
import { contactUsPageVideo } from '../utility/constants';
import SimpleCaptcha from '../components/SimpleCaptcha';

export default function GetQuote() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [capthaVerified, setCapthaVerified] = useState(false);


    const [user, setUser] = useState({country_cd: "", first_name: "", last_name: "", email: "", country: "", org_type: "", message: "", phone_no: ""});
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validateUser = (user) => {
        return user.email === undefined || user.email === null || user.email === ''
        || user.first_name === undefined || user.first_name === null || user.first_name === ''
        || user.last_name === undefined || user.last_name === null || user.last_name === ''
        || (!emailRegex.test(user.email));
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        
        if(name === "country" && value==="USA"){
            setUser({
                ...user,
                ["country_cd"]: "+1",
                [name]: value,
            });
        }else {
            setUser({
                ...user,
                [name]: value,
            });
        }
    };

    const addMessage = async () => {
        let isValid = validateUser(user);
        if(isValid){
            toast.warning("Some fields are missing or not valid!");
        } else if(!capthaVerified){
            toast.warning("Please use the tick mark to verify that you are not robot!")
        }
        else {
            setLoading(true);
            let resp = await addContactUs(user);
            if(resp.error){
                toast.error("Some error occurred!");
            }else{
                setCapthaVerified(false);
                toast.success("Your response is recorded!");
                navigate('/thankyou');
            }
            setLoading(false);
        }

    };
    const [shakeActive, setShakeActive] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShakeActive(false);
          }, 10000);
          return () => clearTimeout(timer);
    },[]);

    const handleSuccess = () => {
        setCapthaVerified(true);
    };
    
    const openVideoPop = async () =>  {
            
            Swal.fire({
                title: "Introduction",
                html: 
                `                        
                <video autoPlay controls>
                    <source src=${contactUsPageVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                ` ,
                width: '80%',
                height: '50%',
                showClass: {
                popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                `
                },
                hideClass: {
                popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                `
                },
                showConfirmButton: true,
                confirmButtonText: "Close",
                allowOutsideClick: false,
                showCloseButton: true,
                closeButtonHtml: '<span style="color: red; font-size: 40px;">&#10006;</span>'
            });
            sessionStorage.removeItem('showIntroVideo');
            
        };

    return (
        <div>
        {loading ? (
            <div className="spinner-container">
            <img src='images/loading.gif' alt='GIF' />
            </div>
        ) : (
        <div>
            <div className='h-[40vh] md:h-[65vh] bg-center bg-no-repeat bg-cover' style={{backgroundImage : "url('../../images/bg-tour.webp')"}}>
                <div className='quote-header py-4 px-5 md:px-10 border-b border-[#fff] flex items-center justify-between'>
                    <div>
                        <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer w-20 md:w-28 brightness-0' src='../images/qlite-logo.webp' alt='qlite logo'/>
                    </div>
                    <div className={shakeActive? 'shake hidden' : 'hidden'}>
                        <button onClick={() => openVideoPop()} className='quote-video-btn w-12 h-12 md:w-14 md:h-14 shadow-lg flex items-center justify-center bg-primary rounded-full p-1.5 hover:bg-black transition duration-300'>
                            <img className='cursor-pointer w-20 md:w-28 brightness-0 transition duration-300' src='../images/quote-video-icon.svg' alt='video icon'/>
                        </button>
                    </div>
                </div>
                <div className='qote-bnr-content mt-[20%] md:mt-[6%] px-5 md:px-10'>
                    <h2 className='text-4xl md:text-5xl text-black inter-700'>Contact Us</h2>
                    <p className='text-base text-black inter-400 mt-4'>Whether you're looking for information or have feedback to share, we'd love to hear from you. Let’s connect!</p>
                </div>
            </div>
            <div className='flex justify-between flex-wrap px-5 lg:px-10 pb-4 translate-y-[-60px]'>
                <div className='w-full md:w-[65%] bg-white md:p-8 p-5 shadow-lg rounded-md mb-6 md:mb-0'>
                    <form>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>First Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="first_name" value={user.first_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Last Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="last_name" value={user.last_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Email <span className='text-[#f00]'>*</span></label>
                                <input type='email' name="email" value={user.email} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Country</label>
                                <select name="country" onChange={handleValueChange} value={user.country} className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                    
                                    <option value="">Select Country</option>
                                    <option value="USA">USA</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Phone </label>
                                <div className='relative'>
                                    <input type='number' name="phone_no" value={user.phone_no} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 pr-6 pl-16  2xl:pr-10 transition duration-300' required/>
                                    <div className='absolute left-0 top-0 w-14'>
                                        <input type='text' name="country_code" value={user.country_cd} placeholder='' className='inter-400 bg-transparent text-black text-base border-r border-[#bbb] focus:border-[#B44D71] text-center h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-2 transition duration-300 inline-block' required disabled/>
                                    </div>
                                </div>  
                            </div>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Organization</label>
                                <select name="org_type" onChange={handleValueChange} value={user.org_type} className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                    <option value="">Select Type</option>
                                    <option value="Government">Government</option>
                                    <option value="DOD">DOD</option>
                                    <option value="Universities">Universities</option>
                                    <option value="Community Colleges">Community Colleges</option>
                                    <option value="High School">High School</option>
                                    <option value="Middle School">Middle School</option>
                                    <option value="Non Profit">Non Profit</option>
                                    <option value="Industry">Industry</option>
                                </select>
                            </div>
                        </div>
                        <div className='mb-5 md:mb-8'>
                            <label className='block mb-2 text-base 2xl:text-xl inter-500 text-black'>Message</label>
                            <textarea name="message" value={user.message} onChange={handleValueChange} className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-28 2xl:h-28 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300'></textarea>
                        </div>
                        <div className='w-full md:w-2/5 mb-5 md:mb-8'>
                                <SimpleCaptcha onSuccess={handleSuccess} />
                        </div>
                        <div className='w-full md:w-2/5'>
                            <button onClick={addMessage} type="button" className='w-full md:w-auto  bg-primary hover:bg-secondary flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Submit</button>
                        </div>
                    </form>
                </div>
                <div className='w-full md:w-[30%] bg-white md:p-8 p-5 shadow-lg rounded-md flex justify-between flex-col'>
                    <div>
                        <h3 className='text-lg text-black inter-600 mb-4'>Details</h3>
                        <ul>
                            <li className='flex items-center mb-4 md:mb-3'>
                                <img src='../images/mail.webp' className='w-6' alt='mail icon' />
                                <a href='mailto:sales@quantumliteracy.org' className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>sales@quantumliteracy.org</a>
                            </li>
                            <li className='flex items-center mb-4 md:mb-3'>
                                <button onClick={() => openVideoPop()} className='quote-video-btn cursor-pointer flex items-center justify-center transition duration-300'>
                                    <img className='w-7 transition duration-300' src='../images/quote-video-icon.webp' alt='video icon'/> <span className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>More information</span>
                                </button>
                                {/* <img src='../images/mail.webp' className='w-6' alt='mail icon' />
                                <a href='mailto:sales@quantumliteracy.org' className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>sales@quantumliteracy.org</a> */}
                            </li>
                            <li className='flex items-center mb-4 md:mb-3 hidden'>
                                <img src='../images/telephone.webp' className='w-6' alt='phone icon' />
                                {/* <a href='mailto:sales@qlitex.com' className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>sales@qlitex.com</a> */}
                            </li>
                            <li className='flex items-center mb-4 md:mb-3 hidden'>
                                <img src='../images/linkedin.webp' className='w-6' alt='linkedin icon' />
                                {/* <a href='mailto:sales@qlitex.com' className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>sales@qlitex.com</a> */}
                            </li>
                            <li className='flex items-center hidden'>
                                <img src='../images/twitter.webp' className='w-6' alt='twitter icon' />
                                {/* <a href='mailto:sales@qlitex.com' className='text-base inter-500 text-black hover:text-primary ml-2 transition duration-300'>sales@qlitex.com</a> */}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className='text-xs text-[#5b5b5b] inter-400'>We respect your privacy and will never share your information with third parties.</p>
                    </div>
                </div>
            </div>
        </div>
        )}
        </div>
    );
}