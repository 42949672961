import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserCol, storeUserDetails } from '../services/UserService';
import { toast } from 'react-toastify';


export default function TermsConditions() {
    const navigate = useNavigate();
    const [comp, setComp] = useState(false);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }
        
    },[]);

    const redirectToDashboard = async () => {
        const userDtl = getUserDetails();
        let data = {col_name: "terms_and_conditions_accepted", value: true};
        const response = await updateUserCol(data, userDtl.token);
        if(response.error === false){
            userDtl.user.terms_and_conditions_accepted = true;
            storeUserDetails(userDtl);
            toast.success('Thank you for accepting terms and conditions!');
            const is_first_time_login = userDtl.user.is_first_time_login;
            const need_password_change = userDtl.user.need_password_change;
            const role = userDtl.user.role;
            if(role === 'ACCOUNT_OWNER'){
                if(is_first_time_login){
                  navigate('/adminguidedtour');
                }else{
                  navigate('/admin/dashboard');
                }
            } else if(role === 'LEARNER'){
                if(need_password_change){
                  navigate('/learner/passwordreset');
                }else if(is_first_time_login){
                    navigate('/learnerguidedtour');
                }
                else{
                    navigate('/learner/dashboard');
                }
                
                
            }
        }else {
            toast.warn('Some Error occurred while storing your consent!')
            navigate("/login");
        }
    };

    const dontConfirm = async () => {
        toast.success('No worries you can review terms and conditions again by logging in the system!')
        navigate("/login");

    };

    return (
        <div className='bg-center bg-no-repeat bg-cover flex items-center justify-center py-[4%] md:py-[4%]' style={{backgroundImage : "url('../../images/bg-tour.webp')"}}>
            <div className='bg-white w-[86%] xl:w-[92%] py-4 lg:py-8 xl:py-12 px-4 lg:px-8 xl:px-20'>
                <div className='relative'>
                    <div className='q-logo lg:relative top-0 left-0'>
                        <img className='w-24 md:w-32 mx-auto' src='../images/b-qlite-logo.svg' alt='qlite logo'/>
                    </div>
                    <div className='q-logo lg:relative mt-8'>
                        <h1 className="poppins-semibold">
                        Disclaimer and Terms of Use for QLITEx

                        </h1>
                    </div>
                    <div className='tour-slider-wrap mt-3'>
                        <div className='tour-slider overflow-hidden lg:h-full mb-8'>
                            <p className='text-base poppins-light mb-3'>
                                By accessing or using QLITEx, you acknowledge and agree to the following terms and conditions:
                            </p>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>1. Intellectual Property Protection:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    All content, including but not limited to courses, materials, videos, text, designs, logos, trademarks, and other intellectual property provided on the Platform (collectively, the “Content”), is owned or licensed by National Quantum Literacy Network (NQLN) and is protected by copyright and other intellectual property laws. Unauthorized reproduction, distribution, modification, or transmission of any Content is strictly prohibited.
                                </p>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>2. License for Personal Use Only:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    You are granted a limited, non-transferable, non-exclusive license to access the Content for personal, non-commercial use only. You agree not to share, resell, or make the Content available to third parties without explicit written permission from NQLN.
                                </p>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>3. Prohibited Activities:</h2>
                                <ul className='list-disc pl-5'>
                                    <li className='text-base poppins-light mb-0'>Copying, downloading, or redistributing Content without authorization.</li>
                                    <li className='text-base poppins-light mb-0'>Using any automated tools (e.g., bots, scrapers) to extract or replicate Content.</li>
                                    <li className='text-base poppins-light mb-0'>Modifying, reverse-engineering, or creating derivative works from the Content.</li>
                                </ul>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>4. Limitation of Liability:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    NQLN shall not be held liable for any damages, losses, or claims arising from the misuse of the Content or any violations of this agreement. You use the Platform at your own risk and are solely responsible for ensuring compliance with all applicable laws.
                                </p>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>5. User Obligations:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    By using the Platform, you affirm that you will not engage in any activity that infringes upon the intellectual property rights of NQLN or its licensors. Any breach of this agreement may result in suspension or termination of access, as well as potential legal action.
                                </p>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>6. Dispute Resolution and Governing Law:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    Any disputes arising from your use of the Platform will be governed by the laws of MD. By agreeing to these terms, you consent to the exclusive jurisdiction of the courts in MD for resolution of disputes.
                                </p>
                            </div>
                            <div class="section mb-5">
                                <h2 className='text-base poppins-medium mb-1'>7. Updates to Terms:</h2>
                                <p className='text-base poppins-light mb-0'>
                                    NQLN reserves the right to update these terms at any time. Continued use of the Platform constitutes acceptance of any changes.
                                </p>
                            </div>
                            <p className='text-base poppins-light mb-0'>
                                By clicking “Accept” or using the Platform, you confirm that you have read, understood, and agreed to these terms. If you do not agree, you must discontinue use of the Platform immediately.
                            </p>
                        </div>
                        <div className='flex items-center justify-between flex-wrap'>
                            <div className='skip-tour mb-2 md:mb-0'>
                                <a onClick={() => redirectToDashboard()} className='cursor-pointer text-sm md:text-lg text-secondary hover:text-tertiary inter-600 underline underline-offset-4 transition duration-300'>I Accept</a>
                            </div>
                            <div className='skip-tour'>
                                <a onClick={() => dontConfirm()} className='cursor-pointer text-sm md:text-lg text-secondary hover:text-primary inter-600 underline underline-offset-4 transition duration-300'>I do not Accept</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}