import React, { useState } from "react";

const SimpleCaptcha = ({ onSuccess }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(true);
    if (onSuccess) {
      onSuccess(); // Trigger a callback when CAPTCHA is completed
    }
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "10px 20px",
        width: "300px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={isChecked}
          style={{ marginRight: "10px", cursor: "pointer" }}
        />
        <span style={{ fontSize: "16px" }}>I am not a robot</span>
      </div>
      {isChecked && (
        <div style={{ color: "green", marginTop: "10px", fontWeight: "bold" }}>
          Verified ✓
        </div>
      )}
    </div>
  );
};

export default SimpleCaptcha;
